import React from "react";
import { graphql } from "gatsby";
import useScript from "../hooks/useScript";

import Layout from "../components/layout";

import IconMail from "../images/icons/theme/communication/send.svg";
import LogoSamsung from "../images/logos/brand/grey-samsung.svg";
import LogoRedbull from "../images/logos/brand/grey-hubspot.svg";
import LogoHubspot from "../images/logos/brand/grey-red-bull.svg";
import LogoTmobile from "../images/logos/brand/grey-t-mobile.svg";
import QoloReview from "../images/logos/qolo-review.svg";
import IconCheck from "../images/icons/interface/check.svg";
import IconLocation from "../images/icons/theme/map/marker-1.svg";
import { GatsbyImage } from "gatsby-plugin-image";
//import { useContactFormMixpanelTracker } from "../hooks/useContactFormMixpanelTracker";
import { ContactJsonLd } from "../components/jsonld/ContactUsJsonLd";
import Testimonials from "../components/Testimonials";

const StartFromScratchPage = ({ data, location: { pathname } }) => {
  const logoClutch = data.logoClutch.childImageSharp.gatsbyImageData;
  //useContactFormMixpanelTracker();

  const TestimonialBlock = ({ data }) => {
    const QoloAvatar = data.QoloAvatar.childImageSharp.gatsbyImageData;

    return (
      <Testimonials
        className="py-0 my-0"
        testimonials={[
          {
            content: `The MVP received positive feedback from the internal team.
                                    Adam Fard UX Studio creates high-quality designs that meet the needs of the client.
                                    The team is hard-working, innovative, and driven.`,
            imageFixed: QoloAvatar,
            logo: (
              <QoloReview
                className="align-items-left"
                style={{ width: "148px" }}
              />
            )
          }
        ]}
      />
    );
  };

  return (
    <Layout
      hideHeader
      headerBgClass="bg-primary-alt"
      hideFooter
      footerBgClass="bg-white"
    >
      <ContactJsonLd />
      <div className="contact-us-page">
        <div className="contact-us-page__bg bg-primary-alt">
          {/*<div className="divider">
            <Divider3 style={{ transform: 'matrix(-1, 0, 0, 1, 0, 0)' }} />
          </div>*/}
        </div>

        <section className="bg-primary-alt">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6">
                <span className="pb-2 pre-heading">
                  Start a product or feature from scratch:
                </span>
                <h1 className="mb-5">Turn an idea into a rapidly scaling product</h1>

                <ul className="list-unstyled">
                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Set yourself on the right path to a product-market fit;
                    </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Go from sketches and idea to tangible design solutions;
                    </span>
                  </li>

                  <li className="d-flex py-2">
                    <div className="icon-round icon-round-full icon-round-xs bg-success mr-2">
                      <IconCheck className="injected-svg icon bg-white" />
                    </div>
                    <span className="lead mt-n1">
                      Empower your product roadmap with user research.
                    </span>
                  </li>
                </ul>

                {/* <TestimonialBlock data={data} /> */}

                <div className="d-none d-md-block">
                  <p className="lead pt-4">You can also reach us via:</p>
                  <ul className="list-unstyled mt-3">
                    <li className="mb-5 d-flex align-items-center">
                      <IconMail className="icon bg-yellow icon-md" />
                      <div className="ml-3 h4 mb-0">
                        <a href="mailto:contact@adamfard.com">
                          contact@adamfard.com
                        </a>
                      </div>
                    </li>
                    <li className="mt-n5">
                      <p>Note: We do not accept unsolicited guest posts</p>
                    </li>
                  </ul>
                </div>

                <div>
                  <ul className="d-flex list-unstyled align-items-center mb-4 mb-md-0">
                    <li className="mr-4">
                      <div>
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href="https://www.goodfirms.co/company/adam-fard-studio"
                        >
                          <img
                            style={{ width: "162px" }}
                            src="https://goodfirms.s3.amazonaws.com/badges/color-badge/mobile-app-design-companies.svg"
                            alt="Top Mobile App Design Company"
                          />
                        </a>
                      </div>
                    </li>
                    <li className="">
                      <a
                        rel="noreferrer"
                        target="_blank"
                        href="https://clutch.co/profile/adam-fard-ux-studio"
                      >
                        <GatsbyImage loading={"lazy"} image={logoClutch} alt="Top UX Agency - Clutch" />
                      </a>
                      {/* <script type="text/javascript" src="https://widget.clutch.co/static/js/widget.js"></script>
                      <div class="clutch-widget" data-url="https://widget.clutch.co" data-widget-type="2" data-snippets="true" data-height="50" data-clutchcompany-id="975724"></div> */}
                    </li>
                  </ul>
                </div>

                {/* <div className="d-none d-md-block">
                  <p className="lead pt-4">Don't know where to start?</p>
                  <a className="lead hover-arrow" href="free-strategy-session">Apply for a Free Strategy Session*</a><br/>
                  <div className="badge badge-primary-2 mt-2">*Only 4 places per month</div>
                </div> */}

                <ul className="list-unstyled d-none d-md-block">
                  <li className="d-flex pt-6">
                    <div className="mr-2">
                      <IconLocation className="injected-svg icon bg-primary-3" />
                    </div>
                    <span className="lead">
                      <strong>Germany</strong>
                      <br />
                      Neue Schönhauser Straße 3 <br />
                      Berlin, BE 10178 <br />
                    </span>
                  </li>

                 {/*  <li className="d-flex pt-4">
                    <div className="mr-2">
                      <IconLocation className="injected-svg icon bg-primary-3" />
                    </div>
                    <span className="lead">
                      <strong>USA</strong>
                      <br />
                      981 Mission St <br />
                      San Francisco, CA 94103 <br />
                    </span>
                  </li> */}
                </ul>
              </div>

              <div className="col-12 col-md-6">
                <div className="card card--common card-body card--no-hover">
                  <div id="activecampaign-form">
                    <div id="activecampaign-form__wrapper">
                      <div className=" _form_3"></div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container mt-4">
                <div className="row text-center mb-3">
                  <div className="col">
                    <small className="text-uppercase">
                      Trusted by companies like
                    </small>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <ul className="Companieslogo d-flex align-items-center justify-content-center list-unstyled">
                      <li data-aos="fade-up" data-aos-delay="100">
                        <LogoSamsung className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="150">
                        <LogoTmobile className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="200">
                        <LogoRedbull className="" />
                      </li>
                      <li data-aos="fade-up" data-aos-delay="250">
                        <LogoHubspot className="" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {useScript("https://adamfard48700.activehosted.com/f/embed.php?id=3")}
    </Layout>
  );
};

export default StartFromScratchPage;

export const query = graphql`query StartFromScratchPage {
  logoClutch: file(relativePath: {eq: "logos/brand/Top_B2B_Western_Europe.png"}) {
    childImageSharp {
      gatsbyImageData(width: 140, layout: FIXED)
    }
  }
  QoloAvatar: file(relativePath: {eq: "photos/qolo-avatar.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 100, height: 100, layout: FIXED)
    }
  }
}
`;
